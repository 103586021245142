const categorySc = [
  "法律服务",
  "会计",
  // "金融",
  // "商业服务",
  // "银行",
  // "保险",
  // "影视行业",
];

export default categorySc;
