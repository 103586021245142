import styled from 'styled-components';

const ContainerWrapper = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #0f319d 0%, #4ebbc6 100%);
`;

const FooterWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  padding: ${(p) => (p.isMobile ? '40px 15px 10px' : p.isTablet ? '40px 0 30px' : '60px 10px 30px')};
  width: 100%;
  max-width: 1620px;
  margin: auto;
  display: flex;
  flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
  justify-content: ${(p) => (p.isTablet ? 'center' : 'space-between')};
`;

const LeftWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 100%;
  max-width: ${(p) => (p.isTablet ? '100%' : '375px')};
  color: ${(p) => p.theme.colors.light};
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: ${(p) => (p.isTablet ? 'center' : 'flex-start')};

  .infoBox {
    display: flex;
    align-items: center;
    flex-direction: ${(p) => (p.isTablet ? 'column' : 'row')};
    gap: 15px;
    text-align: ${(p) => (p.isTablet ? 'center' : 'left')};

    a {
      color: ${(p) => p.theme.colors.light};
    }
  }
`;

const RightWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(p) => (p.isTablet ? 'center' : 'flex-end')};
  gap: 30px;

  .copyright {
    font-size: ${(p) => p.theme.fontSizes.sm};
    text-align: ${(p) => (p.isTablet ? 'center' : 'right')};
    color: ${(p) => p.theme.colors.light};
    margin-top: ${(p) => (p.isTablet ? '30px' : '0')};
  }
`;

const Logo = styled.img`
  width: 356px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 40px;
`;

const Navbar = styled.div<{ isTablet: boolean; isMobile: boolean, justify?: string }>`
  display: ${(p) => (p.isTablet ? 'none' : 'flex')};
  width: 100%;
  gap: 30px;
  align-items: center;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  justify-content: ${(p) => (p.justify ? p.justify : 'flex-start')};

  @media (max-width: 1500px) {
    gap: 15px;
  }
`;

const Anchor = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.md};
  line-height: 23px;
  color: ${(p) => p.theme.colors.light};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
`;

const MediaList = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  flex: 1;
  display: ${(p) => (p.isTablet ? 'none' : 'flex')};
  justify-content: flex-end;
  gap: 20px;
`;

const MediaLink = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
`;

const CopyRight = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: ${(p) => p.theme.fontSizes.base};
  font-weight: 400;
  line-height: 18.75px;
  color: ${(p) => p.theme.colors.light};
  margin-top: auto;
`;

const SearchIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: ${(p) => p.theme.colors.secondary500};
  color: ${(p) => p.theme.colors.light};
  border-radius: 0 5px 5px 0;
  display: flex;

  > img {
    margin: auto;
  }
`;

const SearchBar = styled.form`
  display: flex;
  width: 100%;
`;

export {
  ContainerWrapper,
  FooterWrapper,
  LeftWrapper,
  RightWrapper,
  Logo,
  Navbar,
  Anchor,
  MediaList,
  MediaLink,
  CopyRight,
  SearchIconWrapper,
  SearchBar,
};
