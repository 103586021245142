import { useMemo, useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { cancelToSign, getNeedSignFileList } from '@/api/deal.api';

import Loading from '@/components/UI/Loading';
import { Box, Card, Flex, LoadingPage } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { SignButton, Title, CompanyName } from './styles';
import { theme } from '@/theme/Theme';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import CancelToSignModal from './Modal';
import { NeedUpgradePlanModal } from '@/components/DocLibrary/Modal';

interface ContractProps {
  participantsData?: DealRelation[];
  setNeedSignList: Dispatch<SetStateAction<boolean>>;
}

interface DataRow {
  id: number;
  acc_id: number;
  accessToken: string;
  deal_id: number;
  deal_type_id: number;
  description: string;
  document_library_id: number;
  file_id: number;
  firstSigner: number;
  hash: string;
  identificationId: number;
  neddSign: number;
  signStatus: number;
  signed: number;
  spOrderNum: number;
  uuid: string;
  signA: boolean;
  signB: boolean;
  file: {
    name: string;
    path: string;
  };
  created_at: string;
  uploadByUserId: string;
  PDFASignedDate: string;
  PDFBSignedDate: string;
  cancelSign: number;
  cancelSignUserId: string;
  cancelSignTime: string;
  cancelSignReason: string;
}

const Contract = ({ participantsData = [], setNeedSignList }: ContractProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  const [data, setData] = useState<DataRow[]>([]);

  const [buyerData, setBuyerData] = useState<DealRelation>({} as DealRelation);
  const [sellerData, setSellerData] = useState<DealRelation>({} as DealRelation);
  const dealRoleArray = t('dealRoleArray', { returnObjects: true });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fileId, setFileId] = useState<number>(0);
  const [dealId, setDealId] = useState<string>('0');

  const initData = useCallback(async () => {
    if (!id) return;
    try {
      const res = (await getNeedSignFileList(id)).data;
      if (res.success) {
        const newdata = res.data.map((item: getNeedSignFile) => {
          item.created_at = dateFormatConvert(item.created_at);
          item.cancelSignTime = dateFormatConvert(item.cancelSignTime);
          return {
            ...item,
            signA: item.signPDFBase64A ? true : false,
            signB: item.signPDFBase64B ? true : false,
          };
        });

        setData(newdata);
        if (newdata.length === 0) {
          setNeedSignList(false);
        }
      }
    } catch (error) {
      console.log(error);
    }

    participantsData.map((item) => {
      if (item.tag === 'Buyer') {
        setBuyerData(item);
      }

      if (item.tag === 'Seller') {
        setSellerData(item);
      }
    });
  }, [id]);

  const dateFormatConvert = (created_at: string) => {
    const date = new Date(created_at);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formatDate = `${year}/${month >= 10 ? month : '0' + month}/${day}`;

    return formatDate;
  };

  useEffect(() => {
    initData();
  }, [initData]);

  const cancelSignHandler = async (currentFileId: number) => {
    if (!id) return;
    setFileId(currentFileId);
    setDealId(id);
    setShowModal(true);

    // if (!id) return;
    // setIsLoading(true);
    // try {
    //   const rawData = {
    //     dealId: id,
    //     fileId,
    //   };
    //   // const res = await cancelToSign(rawData);
    //   // console.log(res);
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setIsLoading(false);
    // }
  };

  const checkLevel = (file_id: number) => {
    if (!id) return;
    if (userData.plan.level === null) {
      setShowUpgradeModal(true);
      return;
    }
    navigate(`/deal/${id}/contractSigning/${file_id}`);
  };

  return (
    <Card>
      <LoadingPage isLoading={isLoading} />
      <CancelToSignModal
        initData={initData}
        showModal={showModal}
        setShowModal={setShowModal}
        fileId={fileId}
        dealId={dealId}
      />
      <NeedUpgradePlanModal showModal={showUpgradeModal} setShowModal={setShowUpgradeModal} />
      <Text fontWeight={900} marginBottom={10}>
        {t('pages.dealDetail.contract.title')}
      </Text>
      <Text marginBottom={30}>{t('pages.dealDetail.contract.description')}</Text>
      {data.length > 0 &&
        data.map((item, index) => (
          <Flex key={item.id} gridGap={30} marginBottom={30}>
            <Box width={'100%'} maxWidth={350}>
              <Title>
                <Text>{t('pages.dealDetail.contract.list.title')}</Text>
              </Title>
              <Flex paddingTop={20} width={'100%'} gridGap={20}>
                <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
                  <Flex width={'100%'}>
                    <Text fontWeight={900} color={theme.colors.primary500} style={{ width: '100px' }}>
                      {t('pages.dealDetail.contract.list.fileName')}
                    </Text>
                    <Text style={{ width: '250px', wordBreak: 'break-word' }}>{item.file.name}</Text>
                  </Flex>
                  <Flex width={'100%'}>
                    <Text fontWeight={900} color={theme.colors.primary500} style={{ width: '100px' }}>
                      {item.cancelSign
                        ? t('pages.dealDetail.contract.list.cancelledBy')
                        : t('pages.dealDetail.contract.list.uploadedBy')}
                    </Text>
                    <Text style={{ width: '250px', wordBreak: 'break-word' }}>
                      {item.cancelSign ? item.cancelSignUserId : item.uploadByUserId}
                    </Text>
                  </Flex>
                  <Flex width={'100%'}>
                    <Text fontWeight={900} color={theme.colors.primary500} style={{ width: '100px' }}>
                      {item.cancelSign
                        ? t('pages.dealDetail.contract.list.cancelDate')
                        : t('pages.dealDetail.contract.list.uploadDate')}
                    </Text>
                    <Text style={{ width: '250px', wordBreak: 'break-word' }}>
                      {item.cancelSign ? item.cancelSignTime : item.created_at}
                    </Text>
                  </Flex>
                  {item.cancelSign === 1 && (
                    <Flex width={'100%'}>
                      <Text fontWeight={900} color={theme.colors.primary500} style={{ width: '100px' }}>
                        {t('form.reason.label')}
                      </Text>
                      <Text color={theme.colors.danger} style={{ width: '250px', wordBreak: 'break-word' }}>
                        {item.cancelSignReason}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Box>
            <Box width={'100%'} maxWidth={780}>
              <Title>
                <Text>{t('info.esigns')}</Text>
              </Title>

              <Flex paddingTop={20} width={'100%'} gridGap={30}>
                <Flex width={'100%'} gridGap={10}>
                  <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
                    <Text fontWeight={900} color={theme.colors.primary500}>
                      {dealRoleArray[Number(item.deal_type_id) - 1][0].text}
                    </Text>
                    <Text fontWeight={900} color={theme.colors.primary500}>
                      {t('signedByBuyer')}
                    </Text>
                    <Text fontWeight={900} color={theme.colors.primary500} marginTop={'auto'}>
                      {t('dateSigned')}
                    </Text>
                    {!item.cancelSign && item.signStatus === 2 && <Text>　</Text>}
                  </Flex>
                  <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
                    <CompanyName>{buyerData.name}</CompanyName>
                    {item.cancelSign ? (
                      <SignButton className="cancelled">
                        <div>{t('buttons.cancelled')}</div>
                      </SignButton>
                    ) : (
                      <Text>
                        {item.firstSigner.toString() === buyerData.id.toString() ? (
                          <>
                            {/* 狀態2 */}
                            {!item.signA && (
                              <>
                                {userData.id === buyerData.id && (
                                  <SignButton className="signing" onClick={() => checkLevel(item.id)}>
                                    <div>{t('buttons.clickToSign')}</div>
                                  </SignButton>
                                )}
                                {userData.id !== buyerData.id && (
                                  <SignButton className="waiting">
                                    <div>{t('buttons.waiting')}</div>
                                  </SignButton>
                                )}
                              </>
                            )}
                            {/* 狀態3 */}
                            {item.signA && (
                              <SignButton className="signed">
                                <div>{t('buttons.signed')}</div>
                              </SignButton>
                            )}
                          </>
                        ) : (
                          <>
                            {/* 狀態1 */}
                            {!item.signB && (
                              <SignButton className="waiting">
                                <div>{t('buttons.waiting')}</div>
                              </SignButton>
                            )}
                            {/* 狀態2 */}
                            {item.signB && !item.signA && (
                              <>
                                {userData.id === buyerData.id && (
                                  <Link to={`/deal/${id}/contractSigning/${item.id}`}>
                                    <SignButton className="signing">
                                      <div>{t('buttons.clickToSign')}</div>
                                    </SignButton>
                                  </Link>
                                )}
                                {userData.id !== buyerData.id && (
                                  <SignButton className="waiting">
                                    <div>{t('buttons.waiting')}</div>
                                  </SignButton>
                                )}
                              </>
                            )}
                            {/* 狀態3 */}
                            {item.signB && item.signA && (
                              <SignButton className="signed">
                                <div>{t('buttons.signed')}</div>
                              </SignButton>
                            )}
                          </>
                        )}
                      </Text>
                    )}
                    <Text>{item.PDFASignedDate ? item.PDFASignedDate.replace(/-/g, '/') : '-'}</Text>
                    {!item.cancelSign && item.signStatus === 2 && userData.id === buyerData.id && (
                      <Text
                        color={theme.colors.primary500}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => cancelSignHandler(item.id)}
                      >
                        {t('buttons.cancelToSign')}
                      </Text>
                    )}
                  </Flex>
                </Flex>
                <Flex width={'100%'} gridGap={10}>
                  <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
                    <Text fontWeight={900} color={theme.colors.primary500}>
                      {dealRoleArray[Number(item.deal_type_id) - 1][1].text}
                    </Text>
                    <Text fontWeight={900} color={theme.colors.primary500}>
                      {t('signedBySeller')}
                    </Text>
                    <Text fontWeight={900} color={theme.colors.primary500} marginTop={'auto'}>
                      {t('dateSigned')}
                    </Text>
                    {!item.cancelSign && item.signStatus === 2 && <Text>　</Text>}
                  </Flex>
                  <Flex width={'100%'} flexDirection={'column'} gridGap={15}>
                    <CompanyName>{sellerData.name}</CompanyName>
                    {item.cancelSign ? (
                      <SignButton className="cancelled">
                        <div>{t('buttons.cancelled')}</div>
                      </SignButton>
                    ) : (
                      <Text>
                        {/* 先判斷使用者是否為甲方再決定用signA或signB來做狀態依據, 甲方的話要先看當前狀態(1 = 等待中) (2 = 對方簽完) (3 = 自己簽完) */}
                        {/* 乙方狀態 (2 = 可簽) (3 = 簽完) */}
                        {item.firstSigner.toString() === sellerData.id.toString() ? (
                          <>
                            {/* 狀態2 */}
                            {!item.signB && (
                              <>
                                {userData.id === sellerData.id && (
                                  <SignButton className="signing" onClick={() => checkLevel(item.id)}>
                                    <div>{t('buttons.clickToSign')}</div>
                                  </SignButton>
                                )}
                                {userData.id !== sellerData.id && (
                                  <SignButton className="waiting">
                                    <div>{t('buttons.waiting')}</div>
                                  </SignButton>
                                )}
                              </>
                            )}
                            {/* 狀態3 */}
                            {item.signB && (
                              <SignButton className="signed">
                                <div>{t('buttons.signed')}</div>
                              </SignButton>
                            )}
                          </>
                        ) : (
                          <>
                            {/* 狀態1 */}
                            {!item.signA && (
                              <SignButton className="waiting">
                                <div>{t('buttons.waiting')}</div>
                              </SignButton>
                            )}
                            {/* 狀態2 */}
                            {item.signA && !item.signB && (
                              <>
                                {userData.id === sellerData.id && (
                                  <SignButton className="signing" onClick={() => checkLevel(item.id)}>
                                    <div>{t('buttons.clickToSign')}</div>
                                  </SignButton>
                                )}
                                {userData.id !== sellerData.id && (
                                  <SignButton className="waiting">
                                    <div>{t('buttons.waiting')}</div>
                                  </SignButton>
                                )}
                              </>
                            )}
                            {/* 狀態3 */}
                            {item.signA && item.signB && (
                              <SignButton className="signed">
                                <div>{t('buttons.signed')}</div>
                              </SignButton>
                            )}
                          </>
                        )}
                      </Text>
                    )}
                    <Text>{item.PDFBSignedDate ? item.PDFBSignedDate.replace(/-/g, '/') : '-'}</Text>
                    {!item.cancelSign && item.signStatus === 2 && userData.id === sellerData.id && (
                      <Text
                        color={theme.colors.primary500}
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => cancelSignHandler(item.id)}
                      >
                        {t('buttons.cancelToSign')}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        ))}
    </Card>
  );
};

export default Contract;
