import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import store from '@/redux/store';
import styled from 'styled-components';

import SectionContainer from '@/components/Container/SectionContainer';
import Sidebar from '@/layout/Sidebar';
import Logo from './Logo';
import Navbar from './Navbar';

import { useAppSelector } from '@/redux/store.hook';
import { addUnreadCounter, getAuthSelector, signOut } from '@/redux/auth/auth.slice';
import { disconnect, getNotificationSelector, addNotification } from '@/redux/notification/notification.slice';
import { useDispatch } from 'react-redux';
import { useSocket } from '@/hooks/useSocket';
import { checkUserRegister } from '@/utils/checkUserRegister';
import { clearCart } from '@/redux/cart/cart.slice';
import useResize from '@/hooks/useResize';
import Userbar from '../Userbar';
import CookiePolicy from '@/components/UI/CookiePolicy';
import SEO from '@/components/SEO';

const HeaderWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  justify-content: space-between;
  min-height: 70px;
  margin: 0 auto;
  padding: ${(p) => (p.isMobile ? '20px' : p.isTablet ? '10px 20px' : '30px 10px')};
  position: relative;
`;

const MobileSidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

const Header = () => {
  const location = useLocation();
  const { size, isMobile, isTablet } = useResize();
  const [layoutController, setLayoutController] = useState(false);
  const navigate = useNavigate();

  // user data
  const dispatch = useDispatch();
  const auth = useAppSelector(getAuthSelector);
  const notifications = useAppSelector(getNotificationSelector);
  const { notify } = notifications;
  const { isLogin, userData, registDone, accessToken } = auth;
  const [searchParams, setSearchParams] = useSearchParams();
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);

  useSocket({
    type: 'CONNECT_NOTICE',
    callBack: (WSdata: WSNotification) => {
      if (WSdata.type === '90') {
        // 跳轉到WSdata.message傳來的外部網址
        window.location.href = WSdata.message;
      } else if (WSdata.type === '1') {
        const roomParams = searchParams.get('room') ? searchParams.get('room')! : '';
        // console.log(userData.id, WSdata.triggered_by, searchParams, WSdata.reference_id.toString());
        // 檢查是否為自己發送的訊息 & 檢查是否為當前聊天室, 2者皆否才將未讀數量+1
        if (userData.id !== WSdata.triggered_by || roomParams !== WSdata.reference_id.toString()) {
          dispatch(addUnreadCounter());
        }
      }
      dispatch(addNotification(WSdata));
    },
  });

  const { pathname } = location;
  const publicPage = [
    '/',
    '/auth/forgotpassword',
    '/auth/signin',
    '/auth/signup',
    '/auth/register',
    '/auth/becomeMember',
    '/services',
    '/Subscription',
    '/About',
    '/Features',
    '/eDirectory',
    '/professionalService',
  ];

  useEffect(() => {
    if (isLogin && userData.stage < 4 && pathname !== '/registration') {
      if (pathname === '/auth/signin') return;
      dispatch(disconnect());
      dispatch(signOut());
      dispatch(clearCart());

      // 一秒後再跳轉
      setTimeout(() => {
        navigate('/');
      }, 1000);
    }

    if (publicPage.indexOf(pathname) === -1 && auth.isLogin) {
      setLayoutController(true);
    } else {
      setLayoutController(false);
    }

    // 黃頁獨自拉出來做判斷
    if (pathname === '/eDirectory' && auth.isLogin) {
      setLayoutController(true);
      return;
    }

    // 律師事務所頁獨自拉出來做判斷
    if (pathname === '/professionalService' && auth.isLogin) {
      setLayoutController(true);
      return;
    }

    if (!isLogin) {
      dispatch(disconnect());
    }

    // const doneRegistration = checkUserRegister(userData);

    // console.log(doneRegistration);

    // if(isLogin && pathname !== '/registration' && !doneRegistration) {
    //   navigate('/registration', { replace: true });
    // }

    // 若登入後且在首頁，則跳轉到home
    if (pathname === '/' && isLogin && userData.acc_firstName) {
      navigate('/home', { replace: true });
    } else if (pathname === '/' && isLogin && !userData.acc_firstName) {
      dispatch(disconnect());
      dispatch(signOut());
      dispatch(clearCart());
    }
  }, [pathname, isLogin]);

  useEffect(() => {
    if (isTablet && burgerOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    if (!isTablet && burgerOpen) setBurgerOpen(false);
  }, [size, burgerOpen]);

  useEffect(() => {
    if (isTablet && userOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    if (!isTablet && userOpen) setUserOpen(false);
  }, [size, userOpen]);

  return (
    <>
      <SEO />
      <nav style={{ position: 'relative', zIndex: 10, background: 'white' }}>
      {/* <CookiePolicy /> */}
        <SectionContainer>
          <HeaderWrapper isMobile={isMobile} isTablet={isTablet}>
            <Logo
              setBurgerOpen={setBurgerOpen}
              setUserOpen={setUserOpen}
              registDone={registDone}
              layoutController={layoutController}
              isLogin={isLogin}
            />
            <Navbar
              burgerOpen={burgerOpen}
              userOpen={userOpen}
              setBurgerOpen={setBurgerOpen}
              setUserOpen={setUserOpen}
              registDone={registDone}
              isLogin={isLogin}
              accessToken={accessToken}
              layoutController={layoutController}
            />
          </HeaderWrapper>
        </SectionContainer>
      </nav>

      {isTablet && userOpen && (
        <MobileSidebarWrapper>
          <Userbar setUserOpen={setUserOpen} />
        </MobileSidebarWrapper>
      )}

      {isTablet && burgerOpen && (
        <MobileSidebarWrapper>
          <Sidebar setBurgerOpen={setBurgerOpen} />
        </MobileSidebarWrapper>
      )}
      {auth.isLogin && layoutController && !isTablet && <Sidebar setBurgerOpen={setBurgerOpen} />}
    </>
  );
};

export default Header;
