import { Box, Flex } from '@/layout';
import { useTranslation } from 'react-i18next';
import { ContainerWrapper, LogoPic, Title } from './style';
import useResize from '@/hooks/useResize';

const StrategicPartners = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isTablet, isMobile } = useResize();

  return (
    <ContainerWrapper>
      <Title isTablet={isTablet} isMobile={isMobile}>{t('pages.index.StrageticPartners.title')}</Title>
      <Box>
        <Flex
          flexDirection={isTablet ? 'column' : 'row'}
          alignItems={'center'}
          justifyContent={'center'}
          flexWrap={'wrap'}
          gridGap={'50px'}
        >
          <Flex gridGap={'50px'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
            <LogoPic src="/images/logos/A1.webp" alt="Logo" height={isMobile ? 90 : 110} />
            <LogoPic src="/images/logos/A2.webp" alt="Logo" height={isMobile ? 70 : 90} />
            <LogoPic src="/images/logos/A3.webp" alt="Logo" height={isMobile ? 70 : 90} />
          </Flex>
          <Flex gridGap={'50px'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
            <LogoPic src="/images/logos/B1.webp" alt="Logo" height={70} />
            <LogoPic src="/images/logos/B2.webp" alt="Logo" height={50} />
            <LogoPic src="/images/logos/B3.webp" alt="Logo" height={50} />
            <LogoPic src="/images/logos/B4.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/B5.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/B6.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/B7.webp" alt="Logo" height={65} />
          </Flex>
          <Flex gridGap={'50px'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
            <LogoPic src="/images/logos/C1.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/C2.webp" alt="Logo" height={50} />
            <LogoPic src="/images/logos/C3.webp" alt="Logo" height={75} />
            <LogoPic src="/images/logos/C4.webp" alt="Logo" height={isMobile ? 70 : 100} />
            <LogoPic src="/images/logos/C5.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/C6.webp" alt="Logo" height={65} />
          </Flex>
          <Flex gridGap={'50px'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
            <LogoPic src="/images/logos/D1.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/D2.webp" alt="Logo" height={isMobile ? 80 : 110} />
            <LogoPic src="/images/logos/D3.webp" alt="Logo" height={isMobile ? 50 : 80} />
            <LogoPic src="/images/logos/D4.webp" alt="Logo" height={isMobile ? 50 : 45} />
            <LogoPic src="/images/logos/D5.webp" alt="Logo" height={65} />
            <LogoPic src="/images/logos/D6.webp" alt="Logo" height={50} />
          </Flex>
        </Flex>
      </Box>

      <Box marginTop={40}>
        <Title isTablet={isTablet} isMobile={isMobile}>{t('pages.index.StrageticPartners.supportingOrganisation')}</Title>
        <Flex gridGap={'50px'} flexDirection={isTablet ? 'column' : 'row'} justifyContent={'center'} alignItems={'center'}>
          <LogoPic src="/images/logos/E1.webp" alt="Logo" height={50} />
          <LogoPic src="/images/logos/E2.webp" alt="Logo" height={50} />
          <LogoPic src="/images/logos/E3.webp" alt="Logo" height={65} />
        </Flex>
      </Box>
    </ContainerWrapper>
  );
};

export default StrategicPartners;
