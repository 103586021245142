import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationWrapper, Ul } from '../styles';
import { PDFWrapper } from './styles';
import { Box, Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';
import { Button } from '@/components/UI/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { H5 } from '@/components/UI/Typography/Heading';
import { theme } from '@/theme/Theme';
import usePermissionCheck from '@/hooks/usePermissionCheck';
import { errorHandler } from '@/utils/toastHandler';
import PDFBookReader from './PDFBookReader';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<CreditReportResponse | undefined>>;
}

const Introduction = ({ setCurrentStep, setActiveStep, setData }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [seatchParmas, setSearchParams] = useSearchParams();
  const tips = t('pages.eTrust.creditReport.steps.step1.tips', { returnObjects: true });
  const { isMainAccount } = usePermissionCheck();
  const [PICPdfUrl, setPICPdfUrl] = useState<string>('/files/Credit-Report-Sample.pdf');

  const prevHandler = () => {
    navigate('/home');
  };

  const nextHandler = () => {
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      return;
    }
    setCurrentStep('2');
    setActiveStep(1);
    setSearchParams({ step: '2' });
  };

  useEffect(() => {}, [t]);

  return (
    <PDFWrapper>
      <Flex>
        <div>
          <Text fontSize={theme.fontSizes.lg} marginBottom={20}>
            {t('pages.eTrust.creditReport.steps.step1.title')}
          </Text>
          <H5 marginBottom={50}>
            {t('pages.eTrust.creditReport.steps.step1.intro-top')}
            <br />
            <br />
            {t('pages.eTrust.creditReport.steps.step1.intro-bottom')}
            <Ul>
              {tips.map((tip) => (
                <li key={tip}>{tip}</li>
              ))}
            </Ul>
          </H5>
          <RegistrationWrapper>
            <Flex width={'100%'} flexDirection={'column'} justifyContent={'flex-end'}>
              <Flex>
                <Button
                  size={'lg'}
                  type="button"
                  variant={'primary'}
                  rounded={false}
                  marginRight={40}
                  onClick={nextHandler}
                >
                  {t('buttons.next')}
                </Button>
                <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
                  {t('buttons.cancel')}
                </Button>
              </Flex>
            </Flex>
          </RegistrationWrapper>
        </div>
        <Box width={'600px'}>
          <PDFBookReader pdfUrl={PICPdfUrl} />
        </Box>
      </Flex>
    </PDFWrapper>
  );
};

export default Introduction;
