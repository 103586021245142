const categoryEn = [
  'Legal Services',
  'Accounting',
  // 'Financial Services',
  // 'Business Services',
  // 'Banking',
  // 'Insurance',
  // 'Film Industry',
];

export default categoryEn;
