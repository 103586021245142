const categorySc = [
  "法律服務",
  "影視行業",
  "農業",
  "建築工程",
  "教育",
  "電子",
  "能源",
  "娛樂",
  "金融",
  "醫療保健",
  "飯店及餐飲",
  "進口/出口貿易及批發",
  "資訊科技",
  "機械設備",
  "製造業",
  "媒體及傳播",
  "採礦與挖掘",
  "製藥",
  "商業服務",
  "房地產",
  "零售",
  "社會及個人服務",
  "紡織及製衣",
  "倉庫及速遞服務業",
  "公用事業",
  "廢棄物管理",
  "會計",
  "銀行",
  "保險",
  "其他"
];

export default categorySc;
