import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

import Home from '@/pages/Home';
import ForgotPasswordPage from '@/pages/Auth/ForgotPassword';
import SignInPage from '@/pages/Auth/SignIn';
import SignUpPage from '@/pages/Auth/SignUp';
import Index from '@/pages/Index';
import Registration from '@/pages/Registration';
import Deal from '@/pages/Deal';
import DealInitiation from '@/pages/Deal/DealInitiation';
import DealDetail from '@/pages/Deal/Detail';
import DetailParticipantsPage from '@/pages/Deal/Detail/Participants';
import DetailFilesPage from '@/pages/Deal/Detail/Files';
import ContractSigningPage from '@/pages/Deal/ContractSigning';
import Event from '@/pages/Event';
import YellowPage from '@/pages/YellowPage';
import YellowPageDetail from '@/pages/YellowPage/Detail';
import Translate from '@/pages/Translate';
import Subscription from '@/pages/Subscription';

import PATH from './path';
import Services from '@/pages/Index/Services';
import Pricing from '@/pages/Index/Pricing';
import AboutUs from '@/pages/Index/AboutUs';

import ProfileEdit from '@/pages/Profile/Edit';
import CreditReportEdit from '@/pages/Profile/CreditReport';
import IdentificationEdit from '@/pages/Profile/Identification';
import ECertEdit from '@/pages/Profile/ECert';
import SupplementalInfoEdit from '@/pages/Profile/LEI';
import CompanyInfoEdit from '@/pages/Profile/CompanyInfo';
import PersonalInfoEdit from '@/pages/Profile/PersonalInfo';
import Payment from '@/pages/Payment';
import DocLibrary from '@/pages/DocLibrary';
import MeetingRedir from '@/pages/Event/JoinMeeting';
import DealMeeting from '@/pages/Deal/Detail/meeting';
import NotificationDetails from '@/pages/Deal/Details';
import ExecutionEdit from '@/components/DealList/ExecutionEdit';
import ESignature from '@/pages/ESignature';
import Cart from '@/pages/Cart';
import Checkout from '@/pages/Cart/Checkout';
import CheckoutSuccess from '@/pages/Cart/Success';
import VideoConferencing from '@/pages/Meeting';
import ChatRoom from '@/pages/Chat';
import NewEvent from '@/pages/Event/NewEvent';
import Notifications from '@/pages/Notifications';
import UploadBankCard from '@/pages/Deal/eSign/UploadBankCard';
import CustomGetHWSign from '@/pages/Deal/eSign/CustomGetHWSign';
import AccountEdit from '@/pages/Profile/AccountEdit';
import ResetPassword from '@/pages/Profile/ResetPassword';
import JoinMeeting from '@/pages/Event/JoinMeeting';
import UserComments from '@/pages/Profile/UserComments';
import CompanyComments from '@/pages/YellowPage/CompanyComments';
import Features from '@/pages/Index/Features';
import LEI from '@/pages/ETrust/LEI';
import CreditReport from '@/pages/ETrust/CreditReport';
import ECert from '@/pages/ETrust/ECert';
import AddNewMembers from '@/pages/Profile/AddNewMembers';
import MembersEdit from '@/pages/Profile/AddNewMembers/MembersEdit';
import BeconeMember from '@/pages/Auth/BecomeMember';
import CompanyCreditReport from '@/pages/CompanyCreditReport';
import PaymentDetail from '@/pages/Payment/Detail';
import LEISearch from '@/pages/LEISearch';
import GuestSign from '@/pages/GuestSign';
import ContractExamples from '@/pages/ContractExamples';
// import FeatureDetail from '@/pages/Index/Features/detail';
// import TestMCE from '@/pages/Test';

type AuthState = {
  isLogin: boolean;
  accessToken?: string;
  refreshToken?: string;
  userData: any;
};

interface RoutersProps {
  children: React.ReactNode;
}

interface ProtectProps {
  children: React.ReactElement;
  redir: string;
  auth: AuthState;
}

const Routers = ({ children }: RoutersProps) => {
  const auth = useAppSelector(getAuthSelector);

  const ProtectRouter = ({ auth, redir, children }: ProtectProps) => {
    if (!auth.isLogin) {
      return <Navigate to="/auth/signin" replace={true} state={{ redir }} />;
    }

    // RefreshToken();
    return children;
  };

  return (
    <BrowserRouter>
      {children}
      <Routes>
        <Route index element={<Index />} />
        <Route path={PATH.services} element={<Services />} />
        <Route path={PATH.subscription} element={<Pricing />} />
        <Route path={PATH.about} element={<AboutUs />} />
        <Route path={PATH.features.list} element={<Features />} />
        {/* <Route path={PATH.features.detail} element={<FeatureDetail />} /> */}
        <Route path={PATH.auth.signIn} element={<SignInPage />} />
        <Route path={PATH.auth.forgotPassword} element={<ForgotPasswordPage />} />
        <Route path={PATH.auth.signUp} element={<SignUpPage />} />
        <Route path={PATH.auth.becomeMember} element={<BeconeMember />} />
        <Route path={PATH.home.index} element={<Home />} />
        <Route path={PATH.home.notifications} element={<Notifications />} />
        <Route path={PATH.profile.edit} element={<ProfileEdit />} />
        <Route path={PATH.profile.accountEdit} element={<AccountEdit />} />
        <Route path={PATH.profile.companyinfo} element={<CompanyInfoEdit />} />
        <Route path={PATH.profile.personalinfo} element={<PersonalInfoEdit />} />
        <Route path={PATH.profile.creditReport} element={<CreditReportEdit />} />
        <Route path={PATH.profile.eCert} element={<ECertEdit />} />
        <Route path={PATH.profile.supplementalInfo} element={<SupplementalInfoEdit />} />
        <Route path={PATH.profile.identificationCheck} element={<IdentificationEdit />} />
        <Route path={PATH.profile.resetPassword} element={<ResetPassword />} />
        <Route path={PATH.profile.comments} element={<UserComments />} />
        <Route path={PATH.profile.addNewMembers} element={<AddNewMembers />} />
        <Route path={PATH.profile.editMembers} element={<MembersEdit />} />
        <Route path={PATH.deal.index} element={<Deal />} />
        <Route path={PATH.deal.notificationDetails} element={<NotificationDetails />} />
        {/* <Route path={PATH.deal.executionEdit} element={<ExecutionEdit />} /> */}
        <Route path={PATH.deal.initiation} element={<DealInitiation />} />
        <Route path={PATH.deal.detail.index} element={<DealDetail />} />
        <Route path={PATH.deal.detail.manageParticipants} element={<DetailParticipantsPage />} />
        <Route path={PATH.deal.detail.manageFiles} element={<DetailFilesPage />} />
        <Route path={PATH.deal.detail.contractSigning} element={<ContractSigningPage />} />
        <Route path={PATH.deal.detail.createMeeting} element={<DealMeeting />} />
        <Route path={PATH.eSign.uploadBankCard} element={<UploadBankCard />} />
        <Route path={PATH.eSign.customGetHWSign} element={<CustomGetHWSign />} />
        <Route path={PATH.event.index} element={<Event />} />
        <Route path={PATH.event.newEvent} element={<NewEvent />} />
        <Route path={PATH.event.joinMeeting} element={<JoinMeeting />} />
        <Route path={PATH.meetingRedirect} element={<MeetingRedir />} />
        <Route path={PATH.yellowPage.index} element={<YellowPage type={'YellowPage'} />} />
        <Route path={PATH.yellowPage.detail} element={<YellowPageDetail />} />
        <Route path={PATH.lawFirmsList.index} element={<YellowPage type={'LawFirmsList'} />} />
        <Route path={PATH.lawFirmsList.detail} element={<YellowPageDetail />} />
        <Route path={PATH.yellowPage.comments} element={<CompanyComments />} />
        <Route path={PATH.translate} element={<Translate />} />
        <Route path={PATH.profile.subscriptionDetail} element={<Subscription />} />
        <Route path={PATH.registration} element={<Registration />} />
        <Route path={PATH.lei} element={<LEI />} />
        <Route path={PATH.creditReport} element={<CreditReport />} />
        <Route path={PATH.eCert} element={<ECert />} />
        <Route path={PATH.payment.index} element={<Payment />} />
        <Route path={PATH.payment.detail} element={<PaymentDetail />} />
        <Route path={PATH.docLibrary} element={<DocLibrary />} />
        <Route path={PATH.eSignature} element={<ESignature />} />
        <Route path={PATH.meeting} element={<VideoConferencing />} />
        <Route path={PATH.contractExpamples} element={<ContractExamples />} />
        <Route
          path={PATH.cart.index}
          element={
            <ProtectRouter auth={auth} redir={PATH.cart.index}>
              <Cart />
            </ProtectRouter>
          }
        />
        <Route
          path={PATH.cart.checkout}
          element={
            <ProtectRouter auth={auth} redir={PATH.cart.checkout}>
              <Checkout />
            </ProtectRouter>
          }
        />
        <Route path={PATH.cart.checkoutSuccess} element={<CheckoutSuccess type={'success'} />} />
        <Route path={PATH.cart.cancel} element={<CheckoutSuccess type={'cancel'} />} />
        <Route path={PATH.chatRoom} element={<ChatRoom />} />
        <Route path={PATH.companyCreditReport} element={<CompanyCreditReport />} />
        <Route path={PATH.LEISearch} element={<LEISearch />} />
        <Route path={PATH.GuestSign.success} element={<GuestSign type={'success'} />} />        
        <Route path={PATH.GuestSign.fail} element={<GuestSign type={'failed'} />} />    
        {/* <Route path={PATH.testMCE} element={<TestMCE />} />     */}
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
