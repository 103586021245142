import styled from 'styled-components';

const PDFWrapper = styled.form`
  width: 100%;
  max-width: 1260px;
  padding: 30px 50px;
  border-radius: 15px;
  background: #fff;
`;

export { PDFWrapper };
