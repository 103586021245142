import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

// 手動指定 CDN 上的 worker 檔案路徑
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js`;

interface PDFBookReaderProps {
  pdfUrl: string;
}

const PDFBookReader: React.FC<PDFBookReaderProps> = ({ pdfUrl }) => {
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [pdf, setPdf] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const renderPage = async (pageNum: number) => {
    if (!pdf || !viewerRef.current) return;

    // 清空之前的畫布
    viewerRef.current.innerHTML = '';

    const page = await pdf.getPage(pageNum);
    const viewport = page.getViewport({ scale: 1 });

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = 800;
    canvas.width = viewport.width;

    if (context) {
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    }

    // 檢查current.innerHTML是否為空, 避免重複多次 appendChild
    if (viewerRef.current.innerHTML === '') viewerRef.current.appendChild(canvas);
  };

  const handleNextPage = () => {
    if (currentPage < pdf.numPages) {
      setCurrentPage(currentPage + 1);
      renderPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      renderPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const loadPdf = async () => {
      if (!viewerRef.current) return;

      const loadingTask = getDocument({
        url: pdfUrl,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.9.179/cmaps/',
        cMapPacked: true,
      });
      const pdf = await loadingTask.promise;
      setPdf(pdf);

      // 渲染初始頁面
      await renderPage(1);
    };

    loadPdf();
  }, [pdfUrl]);

  useEffect(() => {
    if (pdf) {
      renderPage(currentPage);
    }
  }, [pdf]);

  return (
    <div className="pdf-modal" style={{ width: '100%' }}>
      <div className="pdf-content" ref={viewerRef} style={{ width: '600px', height: '800px' }}></div>
      <Flex justifyContent="center" alignItems="center" gridGap={'15px'} padding={'20px 0'}>
        <Button variant="primary" type="button" onClick={handlePrevPage} disabled={currentPage === 1}>
          {'<'}
        </Button>
        <span>
          {currentPage} / {pdf?.numPages}
        </span>
        <Button variant="primary" type="button" onClick={handleNextPage} disabled={currentPage === pdf?.numPages}>
          {'>'}
        </Button>
      </Flex>
    </div>
  );
};

export default PDFBookReader;
