const categorySc = [
  "法律服務",
  "會計",
  // "金融",
  // "商業服務",
  // "銀行",
  // "保險",
  // "影視行業",
];

export default categorySc;
